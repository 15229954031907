<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="_add()">新增角色</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%" default-expand-all>
        <el-table-column label="角色名称" prop="name" />
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="操作">
          <template slot-scope="props">
            <div v-if="props.row.id != 1">
              <el-button type="primary" @click="_add(props.row)">编辑</el-button>
              <el-button type="danger" @click="deleteData(props.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-form-item label="角色名称" >
          <el-input v-model="name" placeholder="" />
        </el-form-item>
      </el-form>
      <el-form label-width="100px">
        <el-form-item label="操作权限" >
          <div><el-radio v-model="operation" label="1">所有操作权限</el-radio></div>
          <div><el-radio v-model="operation" label="0">仅查看权限</el-radio></div>
        </el-form-item>
      </el-form>
      <el-form label-width="100px">
        <el-form-item label="菜单权限" >
           <el-checkbox-group v-model="menus">
            <div v-for="(item, index) in menuList" :key="index">
              <div style="font-size: 14px;color: #000;">{{item.name}}</div>
                <el-checkbox v-for="child in item.menuChilds" :label="child.id">{{child.name}}</el-checkbox>
            </div>
           </el-checkbox-group>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTemplate">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {
  data () {
    return {
      name: '',
      list: [],
      menuList: [],
      dialogVisible: false,
      currentId: '',
      operation: '1',
      menus: []
    }
  },
  created () {
    this.GetList();
    this.menuList = window.getMenus();
    console.log(this.menuList)
  },
  methods: {
    
    GetList () {
      this.$api.getRoleList({currentPage: 1, pageSize: 100}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败')
        
        this.list = res.data
      })
    },
    deleteData(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteRole({id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    _add (data) {
      this.dialogVisible = true
      if(data){
        this.currentId = data.id;
        this.name = data.name;
        this.operation = data.operation.toString();
        const list = [];
        data.menusMessage.map(o => {
          if(o.status){
            list.push(o.id)
          }
        })
        this.menus = list;
      }else{
        this.currentId = null;
        this.name = '';
        this.operation = '1';
        this.menus = [];
      }
    },

    saveTemplate () {
      const {name, operation, menus} = this;
      if(!name) return this.$message.error('请输入名称')

      const params = {name, operation, menus};
      let promise = null;
      if(this.currentId){
        params.id = this.currentId;
        promise = this.$api.updateRole(params)
      }else{
        promise = this.$api.AddRole(params)
      }
      promise.then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + res.message)
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },

    
  },
}
</script>
<style lang="scss" scoped>

</style>
